import {
  type ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import {
  type SanityProductFragment,
  type SanityProductVariantFragment,
} from '@data/sanity/queries/types/product'
import { LanguageContext } from './language-context'
import { useMetadataOverride } from './metadata'
import { useActiveVariant, useProductWithInventory } from './product'

interface ProductContextProps {
  product?: SanityProductFragment
  activeVariant?: SanityProductVariantFragment
  setActiveVariantId: (variantId: number) => void
}

interface StringsContextProviderProps {
  product?: SanityProductFragment
  children: ReactNode
}

export const ProductContext = createContext<ProductContextProps>({
  setActiveVariantId: () => null,
})

export const ProductContextProvider = ({
  product: originalProduct,
  children,
}: StringsContextProviderProps) => {
  const { locale } = useContext(LanguageContext)

  const [product, setProduct] = useState<SanityProductFragment>()

  // Active product
  const activeProduct = useMemo(
    () => product ?? originalProduct,
    [originalProduct, product],
  )
  useEffect(() => {
    setProduct(originalProduct)
  }, [originalProduct])

  const activeProductWithInventory = useProductWithInventory(
    locale,
    activeProduct,
  )

  // Active variant
  const [activeVariant, setActiveVariantId] = useActiveVariant(
    activeProductWithInventory?.variants,
    activeProductWithInventory?.options,
    activeProductWithInventory?.optionSettings,
  )

  // Product or product variant metadata
  useMetadataOverride(activeVariant?.seo ?? activeProduct?.seo)

  return (
    <ProductContext.Provider
      value={{
        product: activeProductWithInventory,
        activeVariant,
        setActiveVariantId,
      }}
    >
      {children}
    </ProductContext.Provider>
  )
}
