import { type ReactNode, createContext, useCallback, useMemo } from 'react'

import {
  type SanityProductFragment,
  type SanityProductGalleryPhoto,
  type SanityProductOption,
  type SanityProductOptionSetting,
  type SanityProductVariantFragment,
} from '@data/sanity/queries/types/product'
import { type UrlParameters } from '@lib/hooks'
import { useMetadataOverride } from '@lib/metadata'
import { useActiveVariant } from '@lib/product'
import { getCombinedListingProductOptions } from './helpers'
import {
  useActiveCombinedListing,
  useCombinedListingActiveProduct,
} from './hooks'
import { type ProductCombinedListing } from './types'

interface ProductCombinedListingContextProps {
  activeCombinedListing?: ProductCombinedListing
  activeProduct?: SanityProductFragment
  activeVariant?: SanityProductVariantFragment
  productGalleryPhotos?: SanityProductGalleryPhoto[]
  productOptionSettings?: SanityProductOptionSetting[]
  productVariants?: SanityProductVariantFragment[]
  productOptions?: SanityProductOption[]
  setActiveVariantId: (variantId: number) => void
}

interface ProductCombinedListingContextProviderProps {
  combinedListing?: ProductCombinedListing
  children: ReactNode
}

export const ProductCombinedListingContext =
  createContext<ProductCombinedListingContextProps>({
    setActiveVariantId: () => null,
  })

export const ProductCombinedListingContextProvider = ({
  combinedListing: originalCombinedListing,
  children,
}: ProductCombinedListingContextProviderProps) => {
  const activeCombinedListing = useActiveCombinedListing(
    originalCombinedListing,
  )

  // Compacted product atributes
  const productGalleryPhotos = useMemo(() => {
    return activeCombinedListing?.products?.flatMap(
      (product) => product.galleryPhotos ?? [],
    )
  }, [activeCombinedListing])
  const productOptionSettings = useMemo(() => {
    return activeCombinedListing?.products?.flatMap(
      (product) => product.optionSettings ?? [],
    )
  }, [activeCombinedListing])
  const productVariants = useMemo(() => {
    return activeCombinedListing?.products?.flatMap(
      (product) => product.variants ?? [],
    )
  }, [activeCombinedListing])
  const productOptions = useMemo(() => {
    return activeCombinedListing
      ? getCombinedListingProductOptions(activeCombinedListing.products)
      : undefined
  }, [activeCombinedListing])

  // Active variant
  const transformUrl = useCallback(
    (url: string, parameters: UrlParameters) => {
      const variantId = parameters?.variant?.[0]
        ? Number(parameters.variant[0])
        : null
      const product = activeCombinedListing?.products?.find((product) =>
        product.variants?.some(
          (productVariant) =>
            variantId && productVariant.variantID === variantId,
        ),
      )

      // Replace URL with product slug
      return product?.slug?.current ?? url
    },
    [activeCombinedListing],
  )

  const [activeVariant, setActiveVariantId] = useActiveVariant(
    productVariants,
    productOptions,
    productOptionSettings,
    transformUrl,
  )

  // Active product
  const activeProduct = useCombinedListingActiveProduct(
    activeCombinedListing,
    activeVariant,
  )

  // Product or product variant metadata
  useMetadataOverride(activeVariant?.seo ?? activeProduct?.seo)

  return (
    <ProductCombinedListingContext.Provider
      value={{
        activeCombinedListing,
        activeProduct,
        activeVariant,
        productGalleryPhotos,
        productOptionSettings,
        productVariants,
        productOptions,
        setActiveVariantId,
      }}
    >
      {children}
    </ProductCombinedListingContext.Provider>
  )
}
