import { useContext, useEffect, useState } from 'react'

import { type SanityBlogSettings } from '@data/sanity/queries/types/blog'
import { type SanitySeo } from '@data/sanity/queries/types/seo'
import { type Locale } from './language'
import { LanguageContext } from './language-context'
import { MetadataContext } from './metadata-context'
import { getPageUrl, PageType } from './routes'

/**
 * Blog canonical URL hook.
 */
export const useBlogCanonicalUrl = (
  pageType: PageType,
  slug: string,
  blogSettings?: SanityBlogSettings,
) => {
  const { locale } = useContext(LanguageContext)

  const [canonicalUrl, setCanonicalUrl] = useState<string>()

  useEffect(() => {
    // Check if blog inheritance is set
    if (
      typeof window === 'undefined' ||
      !blogSettings?.inheritContent ||
      !blogSettings?.sourceLocale
    ) {
      return
    }

    const origin = window.location.origin
    const pageUrl = getPageUrl(
      blogSettings.sourceLocale as Locale,
      pageType,
      slug,
    )
    setCanonicalUrl(`${origin}${pageUrl}`)
  }, [locale, pageType, slug, blogSettings])

  return canonicalUrl
}

/**
 * Metadata override hook.
 */
export const useMetadataOverride = (seo?: SanitySeo) => {
  const { setMetadataOverride } = useContext(MetadataContext)

  useEffect(() => {
    setMetadataOverride(seo ?? null)

    return () => {
      setMetadataOverride(null)
    }
  }, [seo, setMetadataOverride])
}
