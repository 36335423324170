import {
  type CartFragmentFragment,
  type GetShopAndCartQuery,
  type GetShopAndCartQueryVariables,
  type GetShopQuery,
  type ShopFragmentFragment,
} from '@data/shopify/storefront/types'
import {
  GET_SHOP,
  GET_SHOP_AND_CART,
} from '@data/shopify/storefront/queries/shop'
import { type Locale } from '@lib/language'
import { type ShopifyClient, queryShopifyStorefront } from './client'

export interface ShopifyShopAndCart {
  shop?: ShopFragmentFragment
  cart?: CartFragmentFragment
}

/**
 * Gets Shopify shop information.
 */
export const getShopifyShop = async (
  locale: Locale,
  shopifyStorefrontGraphQlClient: ShopifyClient,
) => {
  try {
    const getShopResult = await queryShopifyStorefront<GetShopQuery>(
      locale,
      shopifyStorefrontGraphQlClient,
      GET_SHOP,
    )

    if (!getShopResult.data) {
      return
    }

    const shopFragment = getShopResult.data.shop as
      | ShopFragmentFragment
      | undefined

    return shopFragment
  } catch (error) {
    console.log(error)
  }
}

/**
 * Gets Shopify shop information and cart.
 */
export const getShopifyShopAndCart = async (
  locale: Locale,
  shopifyStorefrontGraphQlClient: ShopifyClient,
  cartId: string,
) => {
  try {
    const getShopAndCartResult = await queryShopifyStorefront<
      GetShopAndCartQuery,
      GetShopAndCartQueryVariables
    >(locale, shopifyStorefrontGraphQlClient, GET_SHOP_AND_CART, {
      cartId,
    })

    if (!getShopAndCartResult.data) {
      return
    }

    const shopFragment = getShopAndCartResult.data.shop as
      | ShopFragmentFragment
      | undefined
    const cartFragment = getShopAndCartResult.data.cart as
      | CartFragmentFragment
      | undefined
    const shopifyShopAndCart: ShopifyShopAndCart = {
      shop: shopFragment,
      cart: cartFragment,
    }

    return shopifyShopAndCart
  } catch (error) {
    console.log(error)
  }
}
