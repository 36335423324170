import {
  type ReactNode,
  createContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { type SanitySiteFragment } from '@data/sanity/queries/types/site'
import {
  type CountryCode,
  type CurrencyCode,
  type ShopFragmentFragment,
} from '@data/shopify/storefront/types'
import { CartContextProvider } from './cart/context'
import { type Locale } from './language'
import { getShopifyCartIdStorageKey } from './local-storage'
import { getShopifyDomain } from './shopify/client'
import {
  type ShopifyClient,
  getShopifyStorefrontGraphQlClient,
} from './shopify/graphql/client'
import { type ShopifyShopAndCart } from './shopify/graphql/store'
import { getShopifyCurrency } from './shopify/helpers'
import { getShopAndCart } from './shopify/store'

export interface LocalizedShop {
  locale: Locale
  shopifyShopAndCart: ShopifyShopAndCart | null
  isLoadingFinished: boolean
}

interface ShopContextProps {
  taxRate: number
  shopId?: string
  currency?: string
  currencyCode?: CurrencyCode
  countryCode?: CountryCode
  shopifyDomain?: string
  shopifyPrimaryDomain?: string
  shopifyStorefrontGraphQlClient?: ShopifyClient
}

interface ShopContextProviderProps {
  locale: Locale
  site: SanitySiteFragment
  shopifyShop?: ShopFragmentFragment
  children: ReactNode
}

export const ShopContext = createContext<ShopContextProps>({
  taxRate: 0,
})

export const ShopContextProvider = ({
  locale,
  site,
  shopifyShop,
  children,
}: ShopContextProviderProps) => {
  const [localeInitialised, setLocaleInitialised] = useState<
    Locale | undefined
  >()
  const [localizedShop, setLocalizedShop] = useState<LocalizedShop>({
    locale,
    shopifyShopAndCart: shopifyShop
      ? {
          shop: shopifyShop,
        }
      : null,
    isLoadingFinished: false,
  })

  const shopifyStorefrontGraphQlClient = useMemo(() => {
    try {
      return getShopifyStorefrontGraphQlClient(locale)
    } catch {
      return
    }
  }, [locale])

  const taxRate = useMemo(() => site.cartSettings.taxRate ?? 0, [site])

  // Shopify shop details
  const activeShopifyShop = useMemo(
    () => localizedShop.shopifyShopAndCart?.shop ?? shopifyShop,
    [localizedShop.shopifyShopAndCart, shopifyShop],
  )
  const shopId = useMemo(() => activeShopifyShop?.id, [activeShopifyShop])
  const currency = useMemo(
    () => getShopifyCurrency(activeShopifyShop?.moneyFormat),
    [activeShopifyShop],
  )
  const currencyCode = useMemo(
    () => activeShopifyShop?.paymentSettings?.currencyCode,
    [activeShopifyShop],
  )
  const countryCode = useMemo(
    () => activeShopifyShop?.paymentSettings?.countryCode,
    [activeShopifyShop],
  )
  const shopifyDomain = useMemo(() => getShopifyDomain(locale), [locale])
  const shopifyPrimaryDomain = useMemo(
    () => activeShopifyShop?.primaryDomain?.host ?? shopifyDomain,
    [activeShopifyShop, shopifyDomain],
  )

  // Load Shopify shop and cart (update when switching language)
  useEffect(() => {
    if (localeInitialised === locale) {
      return
    }

    setLocalizedShop({
      locale,
      shopifyShopAndCart: shopifyShop
        ? {
            shop: shopifyShop,
          }
        : null,
      isLoadingFinished: false,
    })

    const loadShop = async () => {
      if (!shopifyStorefrontGraphQlClient) {
        return
      }

      setLocaleInitialised(locale)

      // Get saved cart ID
      const shopifyCartIdStorageKey = getShopifyCartIdStorageKey(locale)
      const cartId = localStorage.getItem(shopifyCartIdStorageKey)

      const shopifyShopAndCart = await getShopAndCart(
        locale,
        shopifyStorefrontGraphQlClient,
        shopifyShop,
        cartId,
      )

      setLocalizedShop({
        locale,
        shopifyShopAndCart: shopifyShopAndCart ?? null,
        isLoadingFinished: true,
      })
    }

    loadShop()
  }, [localeInitialised, locale, shopifyShop, shopifyStorefrontGraphQlClient])

  return (
    <ShopContext.Provider
      value={{
        taxRate,
        shopId,
        currency,
        currencyCode,
        countryCode,
        shopifyDomain,
        shopifyPrimaryDomain,
        shopifyStorefrontGraphQlClient,
      }}
    >
      <CartContextProvider
        localizedShop={localizedShop}
        site={site}
        locale={locale}
      >
        {children}
      </CartContextProvider>
    </ShopContext.Provider>
  )
}
