import { gql } from '@apollo/client'

import { CART_FRAGMENT } from '../fragments'

export const GET_CART = gql`
  ${CART_FRAGMENT}

  query getCart($id: ID!) {
    cart(id: $id) {
      ...CartFragment
    }
  }
`
