import { type ShopFragmentFragment } from '@data/shopify/storefront/types'
import { type Locale } from '@lib/language'
import { getShopifyCart } from './graphql/cart'
import { type ShopifyClient } from './graphql/client'
import {
  type ShopifyShopAndCart,
  getShopifyShop,
  getShopifyShopAndCart,
} from './graphql/store'

/**
 * Gets shop and cart information that is not yet read from Shopify.
 */
export const getShopAndCart = async (
  locale: Locale,
  shopifyStorefrontGraphQlClient: ShopifyClient,
  shopifyShop?: ShopFragmentFragment,
  cartId?: string | null,
) => {
  let shopifyShopAndCart: ShopifyShopAndCart | undefined = undefined

  // When Shopify shop is loaded in page and cart ID isn't known - use Shopify shop from page
  if (shopifyShop && !cartId) {
    shopifyShopAndCart = {
      shop: shopifyShop,
    }
  }

  // When Shopify shop is loaded in page and cart ID is known - use Shopify shop from page and get Shopify cart
  if (shopifyShop && cartId) {
    const newShopifyCart = await getShopifyCart(
      locale,
      shopifyStorefrontGraphQlClient,
      cartId,
    )

    shopifyShopAndCart = {
      shop: shopifyShop,
      cart: newShopifyCart,
    }
  }

  // When Shopify shop isn't loaded in page and cart ID is known - get Shopify shop and get Shopify cart
  if (!shopifyShop && cartId) {
    shopifyShopAndCart = await getShopifyShopAndCart(
      locale,
      shopifyStorefrontGraphQlClient,
      cartId,
    )
  }

  // When Shopify shop isn't loaded in page and cart ID isn't known - get Shopify shop
  if (!shopifyShop && !cartId) {
    const newShopifyShop = await getShopifyShop(
      locale,
      shopifyStorefrontGraphQlClient,
    )

    shopifyShopAndCart = {
      shop: newShopifyShop,
    }
  }

  return shopifyShopAndCart
}
