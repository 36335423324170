import { type SanityClient, createClient } from '@sanity/client'
import { customAlphabet } from 'nanoid'

export const sanityProjectId = process.env
  .NEXT_PUBLIC_SANITY_PROJECT_ID as string
export const sanityDataset = process.env.NEXT_PUBLIC_SANITY_DATASET as string
export const sanityApiVersion = '2023-11-09'

if (!sanityProjectId) {
  throw new Error('Missing NEXT_PUBLIC_SANITY_PROJECT_ID')
}

if (!sanityDataset) {
  throw new Error('Missing NEXT_PUBLIC_SANITY_DATASET')
}

export const getSanityClient = () =>
  createClient({
    projectId: sanityProjectId,
    dataset: sanityDataset,
    apiVersion: sanityApiVersion,
    useCdn: process.env.NODE_ENV === 'production',
    perspective: 'published',
  })

export const getPreviewSanityClient = (token: string) =>
  createClient({
    projectId: sanityProjectId,
    dataset: sanityDataset,
    token,
    apiVersion: sanityApiVersion,
    perspective: 'raw',
    useCdn: false,
    ignoreBrowserTokenWarning: true,
    resultSourceMap: false,
    stega: false,
  })

export const getEditorSanityClient = (token: string) =>
  createClient({
    projectId: sanityProjectId,
    dataset: sanityDataset,
    token,
    apiVersion: sanityApiVersion,
    useCdn: false,
  })

/**
 * Removes unnecessary symbols and whitespace from GROQ query.
 */
const minifyGroqQuery = (query: string) => {
  // Remove multiple spaces and newlines in a row
  let minifiedQuery = query.replace(/\s{2,}/g, ' ')

  // Trim leading and trailing spaces and newlines
  minifiedQuery = minifiedQuery.replace(/^\s+|\s+$/g, '')

  // Remove spaces before and after special characters
  minifiedQuery = minifiedQuery
    .replaceAll(' == ', '==')
    .replaceAll(' != ', '!=')
    .replaceAll(' => ', '=>')
    .replaceAll(' && ', '&&')
    .replaceAll(' || ', '||')
    .replaceAll(' | ', '|')
    .replaceAll(') [', ')[')
    .replaceAll('] {', ']{')
    .replaceAll(' {', '{')
    .replaceAll('{ ', '{')
    .replaceAll(' }', '}')
    .replaceAll('[ ', '[')
    .replaceAll(' ]', ']')
    .replaceAll(': ', ':')
    .replaceAll(', ', ',')

  // Remove commas before closing curly braces
  minifiedQuery = minifiedQuery.replaceAll(',}', '}')

  return minifiedQuery
}

/**
 * Fetches data from Sanity using a GROQ query.
 */
export const fetchQuery = async <T, P = Record<string, never>>(
  sanityClient: SanityClient,
  query: string,
  params?: P,
) => {
  const minifiedQuery = minifyGroqQuery(query)

  return sanityClient.fetch<T>(minifiedQuery, params ?? {})
}

/**
 * Generates a new Sanity item key.
 */
export const generateSanityKey = () => {
  const nanoid = customAlphabet('1234567890abcdef', 16)
  return nanoid()
}
