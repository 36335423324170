import { type KeyboardEvent, useState, useContext } from 'react'
import FocusTrap from 'focus-trap-react'
import { motion } from 'framer-motion'
import cx from 'classnames'

import { type SanityCartSettings } from '@data/sanity/queries/types/cart'
import { CartContext } from '@lib/cart/context'
import DrawerBackdrop from '@components/drawer/backdrop'
import CartForm from './form'

interface CartModalProps {
  cartSettings?: SanityCartSettings
}

const CartModal = ({ cartSettings }: CartModalProps) => {
  const { isCartOpen, openCartInSeparatePage, toggleCart } =
    useContext(CartContext)
  const [hasFocus, setHasFocus] = useState(false)

  if (openCartInSeparatePage) {
    return null
  }

  const handleKeyup = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Escape') {
      toggleCart(false)
    }
  }

  return (
    <>
      <FocusTrap
        active={isCartOpen && hasFocus}
        focusTrapOptions={{ allowOutsideClick: true }}
      >
        <motion.div
          initial="hide"
          animate={isCartOpen ? 'show' : 'hide'}
          variants={{
            show: { x: '0%' },
            hide: { x: '100%' },
          }}
          transition={{ duration: 0.8, ease: [0.16, 1, 0.3, 1] }}
          onKeyUp={(event) => handleKeyup(event)}
          onAnimationComplete={() => setHasFocus(isCartOpen)}
          className={cx(
            'fixed top-0 right-0 w-full h-screen max-w-md z-90 bg-pageBG text-pageText pointer-events-none transition-visibility',
            {
              invisible: !isCartOpen,
              'pointer-events-auto transition delay-[0s]': isCartOpen,
            },
          )}
        >
          <CartForm
            id="cart-modal"
            cartSettings={cartSettings}
            hasCartToggle
            onClick={() => toggleCart(false)}
          />
        </motion.div>
      </FocusTrap>

      <DrawerBackdrop isOpen={isCartOpen} onClick={() => toggleCart(false)} />
    </>
  )
}

export default CartModal
