import groq from 'groq'

import { simplePortableTextFragment } from './content'
import { imageFragment } from './image'

const productPhotosFragment = groq`
  galleryPhotos[] {
    forOption,
    photos[] {
      ${imageFragment}
    },
  },
  listingPhotos[],
`

const productVariantFragment = groq`
  sku,
  barcode,
  title,
  price,
  comparePrice,
  inStock,
  lowStock,
  seo,
  options[],
  variantID,
`

export const productFragment = groq`
  _id,
  _type,
  _createdAt,
  type,
  sku,
  barcode,
  title,
  price,
  comparePrice,
  inStock,
  lowStock,
  surfaceOptions {
    overThumbnail,
    overTitle,
  },
  options[],
  optionNames[],
  slug,
  productID,
  optionSettings[] {
    forOption,
    galleryImage,
    "color": color->color {
      hex,
    },
  },
  filters[] {
    _key,
    forOption,
    filter->{
      _id,
      slug,
    },
  },
  description[] {
    ${simplePortableTextFragment}
  },
  "unstableCollections": collections[] {
    _key,
    "collection": @->{
      _id,
      slug,
      title,
    },
  },
  "variants": *[
    _type == "productVariant" &&
    parentId == ^._id &&
    !wasDeleted &&
    !isDraft
  ] {
    ${productVariantFragment}
  },
  automaticGallery,
  seo,
  ${productPhotosFragment}
`

export const reviewProductFragment = groq`
  productID,
  slug,
  sku,
  title,
  ${productPhotosFragment}
`

const variantQueryFragment = groq`
  title,
  price,
  comparePrice,
  options[],
  variantID,
  "product": *[
    _type == "product" &&
    _id == ^.parentId
  ][0] {
    productID,
    slug,
    title,
    inheritCartPhotos,
    cartPhotos[],
    ${productPhotosFragment}
  },
`

export const productVariantQuery = groq`
  *[
    _type == "productVariant" &&
    variantID == $shopifyProductVariantId &&
    locale == $locale
  ][0] {
    ${variantQueryFragment}
  }
`

export const productVariantsQuery = groq`
  *[
    _type == "productVariant" &&
    variantID in $shopifyProductVariantIds &&
    locale == $locale
  ] {
    ${variantQueryFragment}
  }
`

export const productQuery = groq`
  *[
    _type == "product" &&
    _id == $id
  ][0]
  {
    ${productFragment}
  }
`

export const productsQuery = groq`
  *[
    _type == "product" &&
    _id in $ids
  ]
  {
    ${productFragment}
  }
`

export const productSearchQuery = groq`
  *[
    _type == "product" &&
    locale == $locale &&
    !wasDeleted &&
    !isDraft &&
    (
      title match $query ||
      pt::text(description) match $query
    )
  ]
    | score(
      boost(title match $query, 3.3),
      pt::text(description) match $query,
    )
    | order(_score desc)
    [0...$limit]
  {
    ${productFragment}
    _score,
  }
  [
    _score > 0
  ]
`

export const productVariantsByShopifyIdQuery = groq`
  *[
    _type == "productVariant" &&
    productID == $shopifyProductId
  ] {
    _id,
    variantID,
  }
`

export const filtersAndCollectionsBySlugQuery = groq`
  {
    "collections": *[
      _type == "collection" &&
      slug.current in $collectionSlugs
    ] {
      _id,
      title,
      products[],
    },
    "filters": *[
      _type == "filter" &&
      slug.current in $filterSlugs
    ] {
      _id,
      title,
    },
  }
`

export const combinedListingSettingsQuery = groq`
  *[
    _type == "productCombinedListing" &&
    locale == $locale
  ] {
    _id,
    title,
    "productIds": products[]._ref,
  }
`
